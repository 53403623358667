const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

export const ACCESS_TOKEN_FORMS = 'baf952ea9a100d4c5dc8723ea8512b'
export const SUBMIT_URL_FORMS = `${env.cockpit.url}/api/forms/submit/`
export const VALIDATE_RECAPTCHA_URL = `${env.cockpit.url}/api/recaptcha/validate`

export const SLUGIFY_OPTIONS = {
  lower: true,
  remove: /[*+~.()'"/!:@,«»]/g
}

// breakpoint constants same as in Bootstrap
export const BREAKPOINTS = {
  sm: 576, // Small devices (landscape phones, 576px and up)
  md: 768, // Medium devices (tablets, 768px and up)
  lg: 992, // Large devices (desktops, 992px and up)
  xl: 1200, // Extra large devices (large desktops, 1200px and up)
  xxl: 1400
}
