/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
// import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { getFluidImage } from '../utils/images'
import { getDestinationLabel } from '../utils/helpers'
import faviconDefault from '../images/knecht-favicon-192x192.png'

// Projects
const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

function SEO({ pageContext }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  // Meta tags
  const meta = pageContext?.page?.meta
  const additionalScripts = pageContext?.additionalScripts || null
  const title = meta?.value?.title?.value || ''
  const description = meta?.value?.description?.value || ''
  const noindex = meta?.value?.noindex?.value || false
  const metaDescription = description || site.siteMetadata.description
  const image = pageContext?.page?.headerImage?.value[0]?.path || ''
  const absoluteUrl = pageContext?.absoluteUrl || ''
  const currentPageUrl = pageContext?.url || ''
  const langSlug = pageContext?.langSlug || ''
  const mainDestinationMenuItems =
    pageContext?.knechtDestination?.menuItems?.value || []
  const destinationMenuItems = pageContext?.destination?.menuItems?.value || []

  const { isRootPage, destination, page } = pageContext
  const {
    slug: { value: destinationSlug },
    favicon
  } = destination
  const isHomePage = destinationSlug === env.homepage_destination && isRootPage

  const metaTags = env?.metaTags || []

  const processAdditionalMetaTags = () => {
    return (
      metaTags &&
      metaTags.map((metaTag, index) => {
        // if (
        //   (metaTag?.onlyOnHomepage === true && isRootPage) ||
        //   metaTag?.onlyOnHomepage === false
        // ) {
        if (
          (metaTag?.onlyOnHomepage === true && isHomePage) ||
          metaTag?.onlyOnHomepage === false
        ) {
          return (
            <meta key={index} name={metaTag?.name} content={metaTag?.content} />
          )
        }

        return null
      })
    )
  }

  // const getCurrentDestinationMenuItem = menuItems => {
  //   if (menuItems.length > 0) {
  //     const currentDestinationMenuItem = menuItems?.filter(item => {
  //       const itemUrl = item?.value?.url?.value
  //       let sanitisedItemUrl = itemUrl
  //       if (itemUrl && !itemUrl.startsWith('/')) {
  //         sanitisedItemUrl = `/${itemUrl}`
  //       }
  //       return sanitisedItemUrl === currentPageUrl
  //     })

  //     if (currentDestinationMenuItem && currentDestinationMenuItem.length > 0) {
  //       return currentDestinationMenuItem['0']
  //     }
  //   }

  //   return null
  // }

  const getMetaTitleSuffix = () => {
    let metaTitleSuffix = ''
    const destinationDefaultSuffix = env?.meta?.title?.defaultSuffix || ''

    if (currentPageUrl === '/') {
      // return site.siteMetadata.title
      return destinationDefaultSuffix
    }

    // let currentDestinationMenuItem
    // if (isRootPage) {
    //   // process for destination homepage
    //   currentDestinationMenuItem = getCurrentDestinationMenuItem(
    //     mainDestinationMenuItems
    //   )
    // } else {
    //   currentDestinationMenuItem = getCurrentDestinationMenuItem(
    //     destinationMenuItems
    //   )
    // }

    // if (currentDestinationMenuItem) {
    //   metaTitleSuffix = currentDestinationMenuItem?.value?.text?.value
    // }

    metaTitleSuffix = getDestinationLabel(
      currentPageUrl,
      isRootPage,
      mainDestinationMenuItems,
      destinationMenuItems
    )

    return metaTitleSuffix ? `Ihr ${metaTitleSuffix} Spezialist` : ''
    // return metaTitleSuffix
    //   ? `Ihr ${metaTitleSuffix} Spezialist`
    //   : destinationDefaultSuffix
  }

  const getTitleTemplate = () => {
    const metaTitleSuffix = getMetaTitleSuffix()

    let titleTemplate = `%s`
    if (metaTitleSuffix.length > 0 && metaTitleSuffix !== title) {
      titleTemplate = `%s | ${metaTitleSuffix}`
    }

    return titleTemplate
  }

  const structuredData = env?.structuredData || []

  const removeTrailingSlash = str => {
    if (str.length === 1) {
      return str
    }
    return str.replace(/\/+$/, '')
  }

  const processStructuredData = () => {
    return (
      structuredData &&
      structuredData.map((item, index) => {
        if (
          removeTrailingSlash(item?.useOnPageUrl) ===
          removeTrailingSlash(pageContext?.url)
        ) {
          return (
            <script key={index} type={item?.type} defer>
              {item?.content}
            </script>
          )
        }

        return null
      })
    )
  }

  const composeJobPostingStructuredData = componentData => {
    const today = new Date().toISOString().slice(0, 10)
    const validThrough = `${componentData?.validThrough}T23:59`

    return `{
      "@context" : "https://schema.org/",
      "@type" : "JobPosting",
      "title" : "${componentData?.title || page?.h1?.value}",
      "description" : "${componentData?.description || description}",
      "datePosted" : "${componentData?.datePosted || today}",
      "validThrough" : "${validThrough}",
      "employmentType" : "CONTRACTOR",
      "hiringOrganization" : {
          "@type" : "Organization",
          "name" : "knecht reisen ag",
          "sameAs" : "${componentData?.sameAs || absoluteUrl}",
          "logo" : "https://res.cloudinary.com/kr-reisen/image/upload/v1619595926/knecht/impressum/Knechtreisen_CMYK_Claim_neg_mit_HG_V_iabkna.png"
      },
      "jobLocation": {
      "@type": "Place",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "${componentData?.streetAddress}",
              "addressLocality": "${componentData?.addressLocality}",
              "addressRegion": "${componentData?.addressRegion}",
              "postalCode": "${componentData?.postalCode}",
              "addressCountry": "${componentData?.addressCountry || 'CH'}"
          }
      }
    }`
  }

  const getJobPostingComponents = () => {
    const pageElements = page?.contentElements?.value?.parsed || null

    if (pageElements) {
      const jobPostingComponents = pageElements.filter(el => {
        return el.component === 'StructuredDataJobPosting'
      })

      return jobPostingComponents
    }

    return []
  }

  const processJobPostingStructuredData = () => {
    const components = getJobPostingComponents()

    return components.map((component, index) => {
      return (
        <script type='application/ld+json' key={index}>
          {composeJobPostingStructuredData(component?.settings)}
        </script>
      )
    })
  }
  let canonical
  if (meta?.value?.canonical?.value) {
    canonical = meta?.value?.canonical?.value
    // If canonical is not external link add lang slug
    if (!canonical.includes('http://') && !canonical.includes('https://')) {
      canonical = '/' + langSlug + canonical
    }
  } else if (env.canonical) {
    canonical = absoluteUrl.replace(env.domain, env.canonical)
    // Remove lang slug from canonical if in settings
    if (!env.canonicalWithLangSlug) {
      canonical = canonical.replace('/' + langSlug, '/')
    }
  } else {
    canonical = ''
  }
  const faviconSrc = favicon?.value[0]?.path || faviconDefault
  const faviconLinks = [
    { rel: "icon", sizes: "16x16", href: faviconSrc, type: 'image/png' },
    { rel: "icon", sizes: "32x32", href: faviconSrc, type: 'image/png' },
    { rel: "shortcut icon", href: faviconSrc, type: 'image/png' },
  ];

  const websiteName =
    destination?.websiteName?.value || pageContext?.knechtDestination.name?.value
  const siteName = env?.schemaOrgData?.website?.name || websiteName

  return (
    <>
      <Helmet>
        {pageContext?.host && <base href={pageContext.host} />}
        {canonical && <link rel='canonical' href={canonical} />}
        {noindex && <meta name='robots' content='noindex, nofollow' />}
        {metaTags && processAdditionalMetaTags()}
        {structuredData && processStructuredData()}
        {processJobPostingStructuredData()}
      </Helmet>
      <Helmet
        htmlAttributes={{
          lang: 'de'
        }}
        base={{ href: pageContext?.host || '' }}
        title={title}
        // titleTemplate={`%s | ${site.siteMetadata.title}`}
        titleTemplate={getTitleTemplate()}
        meta={[
          {
            name: `description`,
            content: metaDescription
          },
          {
            property: `og:url`,
            content: absoluteUrl
          },
          {
            property: `og:image`,
            content: image
          },
          {
            property: `og:title`,
            content: title
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            property: `og:site_name`,
            content: siteName
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`
          },
          {
            name: `twitter:title`,
            content: title
          },
          {
            property: `twitter:image`,
            content:
              getFluidImage({
                cloudinary: pageContext?.page?.headerImage?.value[0]?.public_id,
                ar: 1.5,
                width: '20px',
                maxWidth: 768,
                widths: [768]
              })?.src || ''
          },
          {
            name: `twitter:description`,
            content: metaDescription
          }
        ]}
      />
      <Helmet link={faviconLinks}>{additionalScripts}</Helmet>
    </>
  )
}

export default SEO
