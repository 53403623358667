import React, { useState } from 'react'
import { Formik } from 'formik'

import { format } from 'date-fns'
import { de } from 'date-fns/locale'
import ReCAPTCHA from 'react-google-recaptcha'

import ThemeContext from '../../context/ThemeContext'
import {
  textField,
  dateField,
  hiddenField,
  emailField,
  textareaField,
  radioField,
  selectField,
  timeField,
  booleanField,
  submitForm,
  validateRecaptcha
} from './helpers'
import { getComponentClass, getComponentId } from '../../utils/helpers'

// FlyDrive  2481  http://localhost:8000/usa/fly-und-drive --> https://kne.webgarage.ch/amerikareisen/camper/
// Kontakt   2490  http://localhost:8000/usa/hawaii/hochzeitsreisen
// Camper    1493  http://localhost:8000/skandinavien/finnland/motorhome
// Termin    1506  http://localhost:8000/skandinavien/kontakt
// Hotel           http://localhost:8000/usa/loews-atlanta-atlanta
// Roundtrip       http://localhost:8000/usa/hawaii-trails-im-suedpazifik-kona
// Specialists     https://knecht-dev-431ce.firebaseapp.com/usa/spezialisten-team

const Form = ({
  settings,
  defaultValues,
  stringVars,
  handleOnChange,
  dynamicFormValues
}) => {
  const recaptchaRef = React.useRef()
  const componentId = getComponentId(settings)
  const fields = settings?.formData?.fields || []
  let fieldTypes = {}
  fields.forEach(f => {
    fieldTypes[f.name] = f.type
  })
  const envFile = require('../../../env')
  const env = envFile.env[process.env.BENV || 'prod']
  const recaptcha_key =
    env.recaptcha_key || '6LdxImYeAAAAAHnuLavteFiVjOyRkMJnqtVFShYN'
  const domain = env.domain || 'knecht-reisen.ch'
  const [submitMessage, setSubmitMessage] = useState(null)
  // functions for dynamicFieldGroup
  const dynamicFieldGroup = fields.find(f => f.type === 'dynamicFieldGroup')
  const [numberOfItems, setNumberOfItems] = useState(
    dynamicFieldGroup ? dynamicFieldGroup.options.numberOfItems : null
  )
  const addDynamicField = () => {
    setNumberOfItems(numberOfItems + 1)
  }

  const handleSubmitForm = data => {
    setSubmitMessage(data)
  }
  // END: functions for dynamicFieldGroup

  // get all require fields from cockpit form
  let requiredFields = []
  let dependencies = []
  let initialValuesObj = {}

  fields.forEach(field => {
    // required fields & fields to validate
    if (field.required === true || field.options.validate === true) {
      requiredFields.push({
        name: field.name,
        type: field.type,
        label: field.label,
        validate: true,
        validateType: field.options.validationType
      })
    }

    // dependencies
    if ('dependency' in field.options) {
      dependencies.push({
        parent: field.options.dependency.field,
        child: field.name,
        options: field.options.dependency.options
      })
    }
    // initial values
    // console.log(field.options)
    if ('initialValue' in field.options) {
      if (field.name !== 'date_from') {
        initialValuesObj[field.name] = field.options.initialValue
      } else {
        initialValuesObj[field.name] = undefined
      }
    }
    // special initial values
    switch (field.name) {
      case 'destination':
        initialValuesObj[field.name] = settings.destination?.slug?.value
        break
      case 'destinationLabel':
        initialValuesObj[field.name] = settings.destinationLabel
        break
      case 'URL_source_form':
        if (typeof window !== 'undefined') {
          initialValuesObj[field.name] = window.location.href
        }
        break
      case 'recipients':
        if ('destination' in settings) {
          initialValuesObj[field.name] =
            settings.destination?.emailFormSubmissions?.value
        } else if (field.options?.recipients) {
          initialValuesObj[field.name] = field.options?.recipients
        } else {
          initialValuesObj[field.name] = settings.recipients
        }
        break
      default:
        break
    }

    // set hidden fields into initial values
    if (defaultValues) {
      if (field.name in defaultValues) {
        initialValuesObj[field.name] = defaultValues?.[field.name]
      }
    }
  })
  // https://formik.org/docs/overview

  // if no form is selected in component settings, return empty div
  if (!settings?.formData?.name) {
    return <div />
  }

  return (
    <ThemeContext.Consumer>
      {color => (
        <Formik
          initialValues={initialValuesObj}
          validateOnChange={false}
          validateOnBlur={false}
          // form validation
          validate={async values => {
            const errors = {}
            if (requiredFields) {
              requiredFields.forEach(field => {
                // if required field value is empty, remove field from values to display error mesage
                if (values[field.name] === '') {
                  delete values[field.name]
                }
                let fieldName = field.name
                let fieldLabel = field.label
                if (field.validate) {
                  switch (field.type) {
                    case 'email':
                      if (!values.email) {
                        errors.email = 'Required'
                      }
                      if (
                        field.validateType === 'email' &&
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                          values.email
                        )
                      ) {
                        errors.email = 'Ungültige E-Mail Adresse'
                      }
                      break
                    case 'text':
                    case 'textarea':
                    case 'select':
                    case 'date':
                      if (!values[field.name]) {
                        let check = fieldName in values
                        if (!check) {
                          errors[fieldName] =
                            'Feld ' + fieldLabel + ' ist erforderlich'
                        }
                      }
                      break
                    case 'boolean':
                      if (!values[field.name]) {
                        let check = values[fieldName] === true
                        if (!check) {
                          errors[fieldName] = 'Feld ist erforderlich'
                        }
                      }
                      break
                    default:
                      break
                  }
                }
              })
            }
            if (errors) {
              return errors
            }
          }}
          onSubmit={async (
            values,
            { setSubmitting, resetForm, setFieldValue }
          ) => {
            const token = await recaptchaRef.current.executeAsync()
            const result = await validateRecaptcha(
              token,
              domain,
              handleSubmitForm
            )
            if (!result) {
              return
            }
            setSubmitting(false)
            let formName = settings.formData.name
            const delimiter = '__'
            let formValues = { form: values }
            let newElementName = ''
            let newFieldData = ''
            let position = ''
            let lastKey = null
            Object.keys(formValues.form).forEach(element => {
              if (element === '' || formValues.form[element] === undefined) {
                delete formValues.form[element]
              }
              // check if it's dynamicGroupData
              if (element.includes(delimiter)) {
                let value = formValues.form[element]
                const dynamicField = element.split('_')
                newElementName = dynamicField[2]
                lastKey = dynamicField.length - 1
                if (dynamicField[lastKey] !== position) {
                  newFieldData += '::'
                  position = dynamicField[lastKey]
                }
                if (value instanceof Date) {
                  value = format(value, 'dd.MM.yyyy', {
                    locale: de
                  })
                }
                newFieldData += dynamicField[3] + ': ' + value + ', '
                delete formValues.form[element]
              }
            })
            if (newFieldData !== '') {
              formValues.form[newElementName] = newFieldData
            }

            // variable from cockpit form settings
            let formSuccessMessage = settings.formData.successMessage
            let successMessageVar = stringVars?.successMessage
            if (successMessageVar) {
              formSuccessMessage = formSuccessMessage.replace(
                '{successMessage}',
                successMessageVar
              )
            }
            // set form dynamic fields values
            if (dynamicFormValues) {
              Object.keys(dynamicFormValues).forEach(key => {
                if (formValues.form.hasOwnProperty(key)) {
                  const dynamicFormFieldValue = dynamicFormValues[key]
                  formValues.form[key] = dynamicFormFieldValue
                  // setFieldValue(key, dynamicFormFieldValue)
                }
              })
            }

            submitForm(
              fieldTypes,
              formValues,
              formName,
              handleSubmitForm,
              formSuccessMessage,
              resetForm,
              initialValuesObj
            )

            // clear form after submit
            Object.keys(values).forEach(key => {
              if (!initialValuesObj.hasOwnProperty(key)) {
                setFieldValue(key, '')
              }
            })
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue
            /* and other goodies */
          }) => {
            // console.log(values)
            let formElements = []

            let formId = settings.formData._id

            // custom string vars
            let header = stringVars?.header
            let subHeader = stringVars?.subHeader
            let description = stringVars?.desc

            // replace variable from cockpit form
            let formHeader = settings.formData.header
            let formSubHeader = settings.formData.subHeader
            let formDescription = settings.formData.desc

            if ('header' in settings.formData) {
              formHeader = formHeader.replace('{header}', header)
            }
            if ('subHeader' in settings.formData) {
              formSubHeader = formSubHeader.replace('{subHeader}', subHeader)
            }
            if ('desc' in settings.formData) {
              formDescription = formDescription.replace(
                '{description}',
                description
              )
            }

            return (
              <div
                id={componentId}
                className={`bg-white ${getComponentClass(settings)}`}
              >
                <form
                  onSubmit={handleSubmit}
                  onChange={handleOnChange}
                  className='form form-box'
                  label={formId}
                  style={{ position: 'relative' }}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size='invisible'
                    sitekey={recaptcha_key}
                  />
                  {/* TODO: */}
                  {process.env.BENV === 'dev' && (
                    <div className='dev'>
                      <div className='content-element-component'>
                        formular:: {settings.formData.name}
                      </div>
                    </div>
                  )}

                  <div className='form-header'>{formHeader}</div>
                  <div className='form-sub-header'>{formSubHeader}</div>
                  <div className='form-sub-description'>{formDescription}</div>

                  {fields.map((field, index) => {
                    let inputClassName = ''
                    let row = ''
                    if (field.options.class) {
                      inputClassName = field.options.class
                      if (field.options.row) {
                        row = field.options.row
                      }
                    }
                    dependencies.forEach(dependency => {
                      // console.log(dependency)
                      // check if field has some dependecy to other field
                      if (dependency.child === field.name) {
                        inputClassName = field.options.class + ' hidden'
                        let canValidate = false
                        if (Array.isArray(dependency.options)) {
                          dependency.options.forEach(option => {
                            // when input should be visible
                            let canCheck = dependency.parent in values
                            if (
                              canCheck &&
                              values[dependency.parent] === option
                            ) {
                              inputClassName = field.options.class
                              canValidate = true
                            }
                          })
                        }
                        requiredFields.forEach((requiredField, index) => {
                          if (requiredField.type === field.name) {
                            requiredFields[index]['validate'] = canValidate
                          }
                        })
                      }
                    })
                    let fieldName = field.name
                    switch (field.type) {
                      case 'dynamicFieldGroup':
                        let dynamicFieldGroup = []
                        let dynamicFieldGroupItem = []

                        // label for 'dynamicFieldGroup'
                        if (field.label) {
                          dynamicFieldGroup.push(<label>{field.label}</label>)
                        }

                        for (var i = 0; i < numberOfItems; i++) {
                          for (
                            var x = 0;
                            x < field.options.subFields.length;
                            x++
                          ) {
                            let dynamicField = field.options.subFields[x]
                            let dynamicRow = ''
                            let dynamicInputClassName
                            if (dynamicField.options.class) {
                              dynamicInputClassName = dynamicField.options.class
                              if (dynamicField.options.row) {
                                dynamicRow = dynamicField.options.row
                              }
                            }
                            switch (dynamicField.type) {
                              case 'text':
                                dynamicFieldGroupItem.push(
                                  textField(
                                    dynamicField,
                                    dynamicInputClassName,
                                    values,
                                    index,
                                    errors,
                                    handleChange,
                                    i,
                                    fieldName
                                  )
                                )
                                break
                              case 'date':
                                dynamicFieldGroupItem.push(
                                  dateField(
                                    dynamicField,
                                    dynamicInputClassName,
                                    values,
                                    index,
                                    errors,
                                    setFieldValue,
                                    i,
                                    fieldName
                                  )
                                )
                                break
                              default:
                                break
                            }
                            if (dynamicRow === 'end') {
                              const writeDynamicFieldItem = dynamicFieldGroupItem
                              dynamicFieldGroupItem = []
                              dynamicFieldGroup.push(
                                <div className='row'>
                                  {writeDynamicFieldItem.map(element => {
                                    return <>{element}</>
                                  })}
                                </div>
                              )
                            }
                          }
                        }
                        // 'add person' button
                        dynamicFieldGroup.push(
                          <button
                            type='button'
                            className='addPerson'
                            onClick={addDynamicField}
                          >
                            + Person hinzfügen
                          </button>
                        )
                        return dynamicFieldGroup
                      case 'text':
                        formElements.push(
                          textField(
                            field,
                            inputClassName,
                            values,
                            index,
                            errors,
                            handleChange
                          )
                        )
                        break
                      case 'email':
                        formElements.push(
                          emailField(
                            field,
                            inputClassName,
                            values,
                            index,
                            errors,
                            handleChange
                          )
                        )
                        break
                      case 'textarea':
                        formElements.push(
                          textareaField(
                            field,
                            inputClassName,
                            values,
                            index,
                            errors,
                            handleChange
                          )
                        )
                        break
                      case 'radio':
                        formElements.push(
                          radioField(field, inputClassName, index, errors)
                        )
                        break
                      case 'collectionLink':
                      case 'select':
                        formElements.push(
                          selectField(
                            field,
                            inputClassName,
                            index,
                            settings,
                            values,
                            errors
                          )
                        )
                        break
                      case 'boolean':
                        formElements.push(
                          booleanField(
                            formId,
                            index,
                            field,
                            handleChange,
                            inputClassName,
                            values,
                            errors
                          )
                        )
                        break
                      case 'date':
                        formElements.push(
                          dateField(
                            field,
                            inputClassName,
                            values,
                            index,
                            errors,
                            setFieldValue
                          )
                        )
                        break
                      case 'time':
                        formElements.push(
                          timeField(
                            field,
                            inputClassName,
                            values,
                            index,
                            errors,
                            setFieldValue
                          )
                        )
                        break
                      case 'hiddenField':
                        formElements.push(hiddenField(field, values, index))
                        break
                      default:
                        break
                    }
                    if (row === 'end') {
                      const writeElements = formElements
                      formElements = []
                      return (
                        <div className='row' key={index}>
                          {writeElements.map(element => element)}
                        </div>
                      )
                    }
                    return ''
                  })}

                  {submitMessage && (
                    <div className={submitMessage.className}>
                      {submitMessage.message}
                    </div>
                  )}
                  {process.env.BENV === 'dev' && (
                    <div className='form-info'>
                      recipients: {initialValuesObj['recipients']}
                    </div>
                  )}
                  <button
                    className='btn-fill'
                    type='submit'
                    disabled={isSubmitting}
                    style={{ background: color }}
                  >
                    {defaultValues?.newsletter ? 'Anmelden' : 'Senden'}
                  </button>
                </form>
              </div>
            )
          }}
        </Formik>
      )}
    </ThemeContext.Consumer>
  )
}

export default Form
