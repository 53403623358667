import React from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { Field } from 'formik'
import { format } from 'date-fns'
import { cloneDeep } from 'lodash'

import {
  ACCESS_TOKEN_FORMS,
  SUBMIT_URL_FORMS,
  VALIDATE_RECAPTCHA_URL
} from '../../utils/constants'
import 'react-datepicker/dist/react-datepicker.css'
import de from 'date-fns/locale/de'
registerLocale('de', de)

const envFile = require('../../../env')
const env = envFile.env[process.env.BENV || 'prod']

export const createFieldName = (name, keyIndex, parentName) => {
  return '__' + parentName + '_' + name + '_' + keyIndex
}

export const textField = (
  field,
  inputClassName,
  values,
  index,
  errors,
  handleChange,
  keyIndex,
  parentName
) => {
  let fieldName = field.name
  let fieldLabel = field.label + (field?.required ? '*' : '')
  let fieldDescription = field.options.description
  let placeholder = field.options.placeholder

  if (keyIndex !== undefined) {
    fieldName = createFieldName(field.name, keyIndex, parentName)
  }
  return (
    <div key={index} className={inputClassName}>
      <p>
        {(!parentName || parentName) && (
          <>
            <label>{fieldLabel}</label>
            <span className='label-description'>{fieldDescription}</span>
          </>
        )}
      </p>
      <input
        type='text'
        name={fieldName}
        value={values[fieldName]}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {errors[fieldName] ? (
        <div className='form-error'>{errors[fieldName]}</div>
      ) : null}
    </div>
  )
}

export const hiddenField = (field, values, index) => {
  let fieldName = field.name
  return (
    <div key={index}>
      <input type='hidden' name={fieldName} value={values[fieldName]} />
    </div>
  )
}

export const dateField = (
  field,
  inputClassName,
  values,
  index,
  errors,
  setFieldValue,
  keyIndex,
  parentName
) => {
  let fieldName = field.name
  let fieldLabel = field.label + (field?.required ? '*' : '')
  let fieldDescription = field.options.description
  const feType = field?.options?.type
  const isDob = feType === 'dob'

  if (keyIndex !== undefined) {
    fieldName = createFieldName(field.name, keyIndex, parentName)
  }

  let tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  let dobLimit = new Date()
  dobLimit.setDate(dobLimit.getDate() - 1)
  let dobPreselected = new Date()
  dobPreselected.setFullYear(dobPreselected.getFullYear() - 30)

  return (
    <div key={index} className={inputClassName}>
      <p>
        {(!parentName || parentName) && (
          <>
            <label>{fieldLabel}</label>
            <span className='label-description'>{fieldDescription}</span>
          </>
        )}
      </p>
      <DatePicker
        selected={values[fieldName]}
        // selected={isDob ? dobPreselected : values[fieldName]}
        // selected={
        //   isDob && !values[fieldName] ? dobPreselected : values[fieldName]
        // }
        dateFormat='dd.MM.yyyy'
        onChange={date => setFieldValue(fieldName, date || undefined)}
        name={fieldName}
        showMonthDropdown
        showYearDropdown
        yearDropdownItemNumber={isDob ? 100 : 5}
        scrollableYearDropdown
        locale='de'
        autoComplete='off'
        minDate={!isDob ? tomorrow : null}
        maxDate={isDob ? dobLimit : null}
      />
      {errors[fieldName] ? (
        <div className='form-error'>{errors[fieldName]}</div>
      ) : null}
    </div>
  )
}
export const emailField = (
  field,
  inputClassName,
  values,
  index,
  errors,
  handleChange
) => {
  let placeholder = field.options.placeholder
  let fieldLabel = field.label + (field?.required || field?.validate || field?.options?.validate ? '*' : '')
  return (
    <div key={index} className={inputClassName}>
      <p>
        <label>{fieldLabel}</label>
        <span className='label-description'>{field.options.description}</span>
      </p>
      <Field
        type='email'
        name={field.name}
        value={values[field.name]}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {errors.email ? <div className='form-error'>{errors.email}</div> : null}
    </div>
  )
}

export const textareaField = (
  field,
  inputClassName,
  values,
  index,
  errors,
  handleChange
) => {
  let placeholder = field.options.placeholder
  let fieldLabel = field.label + (field?.required ? '*' : '')
  return (
    <div key={index} className={inputClassName}>
      <p>
        <label>{fieldLabel}</label>
        <span className='label-description'>{field.options.description}</span>
      </p>
      <textarea
        name={field.name}
        value={values[field.name]}
        onChange={handleChange}
        placeholder={placeholder}
      />
      {errors[field.name] ? (
        <div className='form-error'>{errors[field.name]}</div>
      ) : null}
    </div>
  )
}

export const radioField = (field, inputClassName, index, errors) => {
  let options = field.options.options
  let fieldLabel = field.label + (field?.required ? '*' : '')
  if (options !== 'undefined') {
    return (
      <div key={index}>
        <div
          className={inputClassName}
          role='group'
          aria-labelledby='my-radio-group'
        >
          <p>
            <label>{fieldLabel}</label>
            <span className='label-description'>{field.description}</span>
          </p>
          {options.length > 0 &&
            options.map((option, index) => (
              <div key={index}>
                <Field
                  type='radio'
                  value={option.key}
                  id={option.key}
                  name={field.name}
                  className='form-check-input'
                />{' '}
                <label
                  name={field.name}
                  value={option.key}
                  htmlFor={option.key}
                  className='radioLabel'
                >
                  {option.name}
                </label>
              </div>
            ))}
          {errors[field.name] ? (
            <div className='form-error'>{errors[field.name]}</div>
          ) : null}
        </div>
      </div>
    )
  }
}

export const selectField = (
  field,
  inputClassName,
  index,
  settings,
  values,
  errors
) => {
  let fieldLabel = field.label + (field?.required ? '*' : '')
  let selectOptions = field.options?.options || ''
  if (field.type === 'select' && typeof selectOptions === 'string') {
    selectOptions = selectOptions.split(',')
  }
  // destination page
  if (field.name === 'destination' && 'destination' in settings) {
    inputClassName = 'hidden'
  }

  return (
    <div key={index} className={inputClassName}>
      <p>
        <label>{fieldLabel}</label>
        <span className='label-description'>{field.description}</span>
      </p>

      <Field
        component='select'
        name={field.name}
        value={values[field.name]}
        style={{ width: '100%' }}
      >
        <option key='0' value={null}>
          bitte auswählen
        </option>
        {
          selectOptions.length > 0 &&
          selectOptions.map(option => {
            let optionKey = option?.key
            let optionName = option?.name
            return (
              <option key={optionKey} value={optionName}>
                {optionName}
              </option>
            )
          })
          // different option value and label, adjust handling of select options [no split]
          // selectOptions.map((option, index) => {
          //   let optionKey = option?.key || option?.name
          //   let optionName = option?.name
          //   return (
          //     <option key={index} value={optionKey}>
          //       {optionName}
          //     </option>
          //   )
          // })
        }
      </Field>

      {errors[field.name] ? (
        <div className='form-error'>{errors[field.name]}</div>
      ) : null}
    </div>
  )
}

export const timeField = (
  field,
  inputClassName,
  values,
  index,
  errors,
  setFieldValue
) => {
  const fieldName = field.name
  let fieldLabel = field.label + (field?.required ? '*' : '')

  // Format time, double digit
  const hoursAndMinutes =
    values[fieldName] &&
    String(values[fieldName].getHours()).padStart(2, '0') +
    '.' +
    String(values[fieldName].getMinutes()).padStart(2, '0')

  return (
    <div key={index} className={inputClassName}>
      <p>
        <label>{fieldLabel}</label>
        <span className='label-description'>{field.description}</span>
      </p>
      <DatePicker
        value={hoursAndMinutes}
        onChange={date => setFieldValue(fieldName, date)}
        name={fieldName}
        showTimeSelect
        showTimeSelectOnly
        timeFormat='HH:mm'
        timeIntervals={15}
        timeCaption='Time'
        autoComplete='off'
      />

      {errors[fieldName] ? (
        <div className='form-error'>{errors[fieldName]}</div>
      ) : null}
    </div>
  )
}

export const booleanField = (
  formId,
  index,
  field,
  handleChange,
  inputClassName,
  values,
  errors
) => {
  let checkboxId = 'checkbox_' + formId + '_' + index
  let fieldLabel = field.label + (field?.required ? '*' : '')
  return (
    <div key={index} className={inputClassName}>
      <input
        type='checkbox'
        name={field.name}
        value={values[field.name]}
        onChange={handleChange}
        className='form-check-input'
        id={checkboxId}
      />
      <label className='checkboxLabel' htmlFor={checkboxId}>
        {fieldLabel}
      </label>
      {errors[field.name] ? (
        <div className='form-error' style={{ marginTop: 0 }}>{errors[field.name]}</div>
      ) : null}
    </div>
  )
}

export const submitForm = (
  fieldTypes,
  formValues,
  formName,
  handleSubmitForm,
  successMessage,
  resetForm,
  initialValues
) => {
  let data = cloneDeep(formValues)

  Object.keys(data.form).forEach(fieldName => {
    // format dates here, because JSON.stringify would mess up the timezone
    if (fieldTypes[fieldName] === 'date') {
      let value = data.form[fieldName]
      const formatted = format(value, 'dd.MM.yyyy', {
        locale: de
      })
      data.form[fieldName] = formatted
    } else if (fieldTypes[fieldName] === 'time') {
      let value = data.form[fieldName]
      const formatted = format(value, 'HH:mm', {
        locale: de
      })
      data.form[fieldName] = formatted
    }
  })

  data = JSON.stringify(data)

  let axios = require('axios')
  var config = {
    method: 'post',
    url: SUBMIT_URL_FORMS + formName + '?token=' + ACCESS_TOKEN_FORMS,
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  }

  axios(config)
    .then(function (response) {
      if (response.status === 200) {
        handleSubmitForm({
          message: successMessage,
          className: 'display-success'
        })
        resetForm(initialValues)
      }
    })
    .catch(function (error) {
      console.log(error)
      handleSubmitForm({
        message:
          'Das Formular wurde nicht gesendet. Bitte versuchen Sie es in wenigen Minuten noch einmal. [' +
          error.response.status +
          ' ' +
          error.response.statusText +
          ']',
        className: 'display-error'
      })
    })
}

export const validateRecaptcha = async (token, domain, handleSubmitForm) => {
  var bodyFormData = new FormData()
  bodyFormData.append('g-recaptcha-response', token)
  bodyFormData.append('domain', domain)
  let axios = require('axios')
  var config = {
    method: 'post',
    url: VALIDATE_RECAPTCHA_URL + '?token=' + env.cockpit.accessToken,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    data: bodyFormData
  }

  const response = await axios(config)
    .then(function (response) {
      if (!response.data.success) {
        throw new Error()
      } else {
        return true
      }
    })
    .catch(function (error) {
      handleSubmitForm({
        message: 'Recaptcha error',
        className: 'display-error'
      })
      return false
    })
  return response
}
