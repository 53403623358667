import React, { useEffect } from "react"
import PropTypes from "prop-types"

import ThemeContext from "../context/ThemeContext"
import Header from "../components/Header"
import Footer from "../components/Footer"
// import Breadcrumbs from '../components/Breadcrumbs'
// import BackButton from '../components/BackButton'
import SEO from "../components/seo"
// import CookieBanner from "../components/CookieBanner"
// import Cookiebot from "../components/Cookiebot"
import GoogleTagManager from "../components/GoogleTagManager"
import ProjectRedirect from "../components/Functions/ProjectRedirect"

import "../assets/styles/main.sass"

// const envFile = require("../../env")
// const env = envFile.env[process.env.BENV || "prod"]

const MainLayout = ({ children, pageContext }) => {
  const destinationColor = pageContext.destination.color?.value
  // const { isHomePage } = pageContext

  useEffect(() => {
    // Change theme colors for body (mobile header and footer toobar)
    if (destinationColor) {
      // Body color
      // document.getElementById('gatsby-body').style.background = destinationColor

      // Hubspot CTA video footer banner background color
      let style = document.createElement("style")
      const newContent = document.createTextNode(
        "html .leadinModal-2087328.leadinModal-theme-bottom-right-corner.leadinModal-preview.leadinModal-v3 .leadinModal-content {background: " +
          destinationColor +
          " !important;}"
      )
      style.appendChild(newContent)
      document.getElementsByTagName("head")[0].appendChild(style)
    }
  }, [destinationColor])

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { pageContext: pageContext })
    }
    return child
  })

  return (
    <ThemeContext.Provider value={destinationColor}>
      <>
        <SEO pageContext={pageContext} />
        {/* <Cookiebot cbid={env.cookiebotSettings} /> */}
        {/* <CookieBanner pageContext={pageContext} /> */}
        <GoogleTagManager/>
        <ProjectRedirect pageContext={pageContext} />

        <Header pageContext={pageContext} />

        <div className="container content-body">
          {/* {!isRootPage && <Breadcrumbs />} */}
          {/* {!isHomePage && <BackButton />} */}
          <main>{childrenWithProps}</main>
        </div>

        <Footer pageContext={pageContext} />
      </>
    </ThemeContext.Provider>
  )
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default MainLayout
