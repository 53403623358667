import React, { useEffect, useCallback } from 'react'
// alternative solution for HS forms - 3rd party plugin
// import HubspotForm from 'react-hubspot-form'
import { processHubSpotForms } from '../../functions'

// import { isDomAvailable } from '../utils/helpers'

// match the region value using a regular expression
const getHubSpotRegion = formSnippet => {
  if (!formSnippet) return null
  const regionMatch = formSnippet.match(/region:\s*['"]([^'"]+)['"]/)
  const region = regionMatch ? regionMatch[1] : null
  return region
}

// match the portalId value using a regular expression
const getHubSpotPortalId = formSnippet => {
  if (!formSnippet) return null
  const portalIdMatch = formSnippet.match(/portalId:\s*['"]([^'"]+)['"]/)
  const portalId = portalIdMatch ? portalIdMatch[1] : null
  return portalId
}

// match the formId value using a regular expression
const getHubSpotFormId = formSnippet => {
  if (!formSnippet) return null
  const formIdMatch = formSnippet.match(/formId:\s*['"]([^'"]+)['"]/)
  const formId = formIdMatch ? formIdMatch[1] : null
  return formId
}

// function to check if a script with a specific src attribute is present
const isScriptAlreadyLoaded = src => {
  var scripts = document.getElementsByTagName('script');
  for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].getAttribute('src') === src) {
          return scripts[i]
      }
  }
  return null
}

// const HUBSPOT_FORMS_SRC = '//js.hsforms.net/forms/v2.js'
// const HUBSPOT_FORMS_SRC = '//js.hsforms.net/forms/shell.js'
const HUBSPOT_FORMS_SRC = '//js.hsforms.net/forms/embed/v2.js'

// const envFile = require('../../env')
// const env = envFile.env[process.env.BENV || 'prod']

const FormElement = props => {
  const { portalId, formId, region, submitBtnClass } = props

  const loadHsForm = useCallback(() => {
    if (typeof window !== 'undefined' && window?.hbspt && window?.hbspt.forms) {
      window.hbspt.forms.create({
        region,
        portalId,
        formId,
        submitButtonClass: `hs-button primary large ${submitBtnClass}`,
        target: `#hsForm-${formId}`,
        formInstanceId: `${formId}`,
        // locale: 'de',
        // inlineMessage: '',
        onFormReady: function (form) {
          // console.log('On Form ready!', form)
          props.onReady(form)
        }
      })
    }
  }, [portalId, formId, region, submitBtnClass, props])

  if (typeof window !== 'undefined') {
    loadHsForm()
  }

  useEffect(() => {
    let script = isScriptAlreadyLoaded(HUBSPOT_FORMS_SRC)
    if (!script) {
      script = document.createElement('script')
      script.src = HUBSPOT_FORMS_SRC
      document.head.appendChild(script)   // body or head
    }
    script.addEventListener('load', () => {
      if (typeof window !== 'undefined') {
        loadHsForm()
      }
    })

    // return () => {
    //   document.body.removeChild(script)
    // }
  }, [portalId, formId, region, submitBtnClass, props, loadHsForm])

  return <div id={`hsForm-${formId}`} />
}

const isString = str => {
  return typeof str === 'string' || str instanceof String
}

const processRecipients = (values, recipientsStr) => {
  if (recipientsStr) {
    let recipients = recipientsStr.split(',')
    for (let i = 0; i < recipients.length; i++) {
      if (i > 0) {
        values[`recipients_${i + 1}`] = recipients[i]
      } else {
        values['recipients'] = recipients[i]
      }
    }
  }
  return values
}

// const FORMS_WITH_NEW_STYLING = [
//   'Spezialisten',
//   'SpezialistenStrandreisen',
//   'SpezialistenSport',    // overwritten by Spezialisten
//   'SpezialistenGeschaefts',
//   'Termin',
//   'TerminStrandreisen',
//   'TerminanfrageSport'    // overwritten by Termin
// ]

const isFormWithNewStyling = identifier => {
  return true
  // return FORMS_WITH_NEW_STYLING.includes(identifier)
}

const capitalizeFirstLetter = string => {
  if (!string) {
    return ''
  }
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const HubSpotForm = props => {
  // if (!isDomAvailable()) {
  //   return <p className='loading'>Loading...</p>
  // }

  const {
    settings: mainFormSettings,
    additionalSettings,
    defaultValues,
    stringVars,
    handleOnChange,
    hideFormFields
  } = props

  const allHsForms = props?.allHsForms || []
  const processedHsForms =
    allHsForms && allHsForms.length > 0 ? processHubSpotForms(allHsForms) : []
  const skipOverrideCheck = additionalSettings?.embeddedFormComponent || false
  const destinationPageContext = props?.pageContext?.destination || null
  const destinationAditionalSettings = additionalSettings?.destination || null
  const destination = destinationAditionalSettings
    ? destinationAditionalSettings
    : destinationPageContext
  const destinationSlug = destination?.slug?.value

  let settings = mainFormSettings
  if (processedHsForms && !skipOverrideCheck && destinationSlug) {
    // check if form has override
    let defaultFormSettings = null
    Object.keys(processedHsForms).forEach(formKey => {
      const hsForm = processedHsForms[formKey]
      if (
        hsForm?.overrideForm === settings?.identifier &&
        hsForm?.overrideForDestinations.includes(destinationSlug)
      ) {
        // override form
        // use only "settings"
        defaultFormSettings = settings
        settings = hsForm

        // if fallback implemented to default one, check the if settings.snippet exists
      }
    })

    if (defaultFormSettings !== null) {
      console.info(
        `Handling HS form override for "${defaultFormSettings?.identifier}", replaced by "${settings?.identifier}"`
      )
    }
  }

  if (!settings) {
    console.warn('HubSpotForm data are not provided! Props: ', props)
    return null
  }

  if (!settings?.snippet) {
    console.warn(
      'HubSpotForm snippet is not provided! Should fallback to CMS form or component config issue.'
    )
    return null
  }

  // const { snippet: formSnippet, identifier, name: formName } = settings
  const { snippet: formSnippet, submitBtnClass } = settings

  const region = getHubSpotRegion(formSnippet)
  const portalId = getHubSpotPortalId(formSnippet)
  const formId = getHubSpotFormId(formSnippet)

  // custom string vars
  let header = isString(stringVars?.header) ? stringVars?.header : null
  let subHeader = isString(stringVars?.subHeader) ? stringVars?.subHeader : null
  let description = isString(stringVars?.desc) ? stringVars?.desc : null

  const hideFormDescription = additionalSettings?.hideFormDescription || false

  // pre-process initial values
  let initialValues = {}
  initialValues['destination'] = additionalSettings?.destination?.slug?.value
  initialValues['destinationlabel'] = additionalSettings?.destinationLabel
  initialValues['url_source_form'] =
    typeof window !== 'undefined' ? window.location.href : null
  // initialValues['recipients'] =
  const recipientsStr =
    additionalSettings && 'destination' in additionalSettings
      ? additionalSettings?.destination?.emailFormSubmissions?.value
      : additionalSettings?.recipients
  // middle case if field.options?.recipients
  initialValues = processRecipients(initialValues, recipientsStr)

  // Resource: https://legacydocs.hubspot.com/docs/methods/forms/advanced_form_options
  const onFormReady = form => {
    // const event = new Event('change')
    // const eventChange = new Event('change', { bubbles: true, cancelable: true })
    // const eventInput = new Event('input', { bubbles: true, cancelable: true })
    const eventInput = new Event('input', { bubbles: true })

    // hack: not the same form object on local and prod instances
    // const sanitisedForm = (typeof form === 'object' && !Array.isArray(form) && form !== null) ? form[0] : form
    // let sanitisedForm = (form && env.mode === 'prod') ? form[0] : form

    // better solution
    let sanitisedForm = form
    if (typeof sanitisedForm.querySelector !== "function") { 
      sanitisedForm = sanitisedForm[0]
    }

    // handle form fields visibility
    if (hideFormFields) {
      hideFormFields.forEach(key => {
        const el = sanitisedForm.querySelector(`.hs_${key}`)
        if (el) {
          el.style.display = 'none'
        }
      })
    }

    // pre-select initial values
    if (initialValues) {
      const keys = Object.keys(initialValues)
      keys.forEach(key => {
        const value = initialValues[key]
        const el = sanitisedForm.querySelector(
          // `#hsForm-${formId} .hs-form-field input[name="${key}"]`
          `input[name="${key}"]`
        )

        if (el) {
          el.value = value
          el.dispatchEvent(eventInput)
        }
      })
    }

    // pre-select default values
    if (defaultValues) {
      let processedDefaultValues = defaultValues
      const recipientsStr = defaultValues?.recipients || null
      processedDefaultValues = processRecipients(defaultValues, recipientsStr)
      const keys = Object.keys(processedDefaultValues)
      keys.forEach(key => {
        const value = processedDefaultValues[key]

        // const el = document.querySelector(
        //   `#hsForm-${formId} .hs-form-field input[name="${key}"]`
        // )

        const el = sanitisedForm.querySelector(
          // `#hsForm-${formId} .hs-form-field input[name="${key}"]`
          `input[name="${key}"]`
        )

        if (el) {
          el.value = value
          el.dispatchEvent(eventInput)
        }
      })
    }
  }

  // use in useEffect
  // if (typeof window !== "undefined") {
  //   window.addEventListener('message', event => {
  //     if(event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormReady') {
  //       // the form is ready to manipulate!
  //     }
  //   })
  // }

  // replace variable from cockpit form
  let formHeader = settings?.formHeader || header
  let formSubHeader = settings?.formSubHeader || subHeader
  let formDescription = settings?.formDescription || description

  // const hasNewStyling = isFormWithNewStyling(mainFormSettings?.identifier)
  const hasNewStyling = isFormWithNewStyling(settings?.identifier)

  if (settings?.formHeader) {
    formHeader = formHeader.replace('{header}', header || '')
  }
  if (settings?.formSubHeader) {
    formSubHeader = formSubHeader.replace('{subHeader}', subHeader || '')
  }
  if (settings?.formDescription) {
    formDescription = formDescription.replace('{description}', description || '')
  }

  if (hideFormDescription) {
    formDescription = null
  }

  if (hasNewStyling) {
      const destName = destinationAditionalSettings?.name?.value
      if (formHeader) {
        formHeader = formHeader.replace('{destinationName}', capitalizeFirstLetter(destName))
      }

      const destSlug = destinationAditionalSettings?.slug?.value
      const destWebsiteName = destinationAditionalSettings?.websiteName?.value
      const destBackLink = `<a href="/${destSlug}">${destWebsiteName}</a>`
      if (formDescription) {
        formDescription = formDescription.replace('{destinationLink}', destBackLink)
      }
  } else {
    // temporary solution
    if (formHeader) {
      formHeader = formHeader.replace('{destinationName}', '')
    }
    if (formDescription) {
      formDescription = formDescription.replace('{destinationLink}', '')
    }
  }

  return (
    <div className={`hubspot-form-wrapper ${hasNewStyling ? 'hs-form-new-styling' : ''}`}>
      {formHeader && <div className='form-header'>{formHeader}</div>}
      <div className='form-sub-header'>{formSubHeader}</div>
      {formDescription && (
        <div className='form-sub-description' dangerouslySetInnerHTML={{ __html: formDescription }}></div>
      )}
      {/* Custom implementation */}
      <FormElement
        portalId={portalId}
        formId={formId}
        region={region}
        submitBtnClass={submitBtnClass}
        onReady={form => onFormReady(form)}
        onChange={event => handleOnChange(event)}
      />

      {/* Alternative solution for HS forms - 3rd party plugin */}
      {/* without form customisations */}
      {/* <HubspotForm
          portalId={portalId}
          formId={formId}
          region={region}
          // onSubmit={() => console.log('Submit!')}
          onReady={(form) => onFormReady(form)}
          loading={<div>Loading...</div>}
      /> */}
      
    </div>
  )
}

export default HubSpotForm
