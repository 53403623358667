import React from 'react'
import starIcon from '../../assets/icons/Star-icon.svg'

const RatingStars = props => {
  const elements = []
  if (props?.num) {
    // Check if the rating has half star
    const starCount = Number.isInteger(props.num) ? props.num : props.num + 0.5
    const halfStarStyle = {
      clipPath: 'polygon(0 100%, 50% 100%, 50% 0, 00% 0%)'
    }
    for (let i = 1; i <= starCount; i++) {
      elements.push(
        <span key={i}>
          <img
            src={starIcon}
            alt='Hotel Rating'
            title='Hotel Rating'
            style={i > props.num ? halfStarStyle : {}}
            loading='lazy'
          />
        </span>
      )
    }
  }
  return elements
}

export default RatingStars
