import React from 'react'
import {
  Map as LeafletMap,
  TileLayer,
  ZoomControl,
  Marker
} from 'react-leaflet'

import { useConfigureLeaflet } from '../hooks'
import { isDomAvailable } from '../utils/helpers'

import 'leaflet/dist/leaflet.css'

const Map = props => {
  useConfigureLeaflet()

  if (!isDomAvailable()) {
    return <p className='map-loading'>Loading map...</p>
  }

  const { center, zoom, height } = props

  const basemap = {
    name: 'OpenStreetMap',
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
  }

  const mapSettings = {
    center,
    zoom,
    className: 'map-base',
    zoomControl: false
  }

  return (
    <LeafletMap {...mapSettings} style={{ height }}>
      <Marker position={center} />
      <TileLayer {...basemap} />
      <ZoomControl position='bottomright' />
    </LeafletMap>
  )
}

export default Map
