const _ = require(`lodash`)
const axios = require('axios')

async function getApiData (endpoints) {
  return Promise.all(endpoints.map(getEndpointData))
}

function getEndpointData (url) {
  return axios
    .get(url)
    .then(function (response) {
      return response.data
    })
    .catch(function (error) {
      throw new Error(error)
    })
}

const filterProducts = (items, filtersString) => {
  if (!filtersString) return null

  const filters = filtersString.split('|').map(f => f.trim())
  const filterGroups = {
    Geo: { IN: [], NOT_IN: [] }, // Geo IN '{country}/{region}/{location}'
    Traveltypes: { IN: [], NOT_IN: [] },
    HotelType: { IN: [], NOT_IN: [] }
  }
  filters.map(f => {
    Object.keys(filterGroups).forEach(type => {
      if (f.startsWith(type)) {
        if (f.includes('NOT IN')) {
          f = f.replace(`${type} NOT IN `, '').slice(1, -1)
          if (type === 'Geo') {
            const geoParts = f.split('/')
            const geoFilters = {
              country: { name: geoParts[0] },
              region: { name: geoParts[1] },
              location: { name: geoParts[2] }
            }
            filterGroups[type]['NOT_IN'].push(
              _.pickBy(geoFilters, gf => gf.name)
            )
          } else {
            // f.split('/').forEach(part =>
            //   filterGroups[type]['NOT_IN'].push(part)
            // )
            filterGroups[type]['NOT_IN'].push(f)
          }
        } else {
          f = f.replace(`${type} IN `, '').slice(1, -1)
          if (type === 'Geo') {
            const geoParts = f.split('/')
            const geoFilters = {
              country: { name: geoParts[0] },
              region: { name: geoParts[1] },
              location: { name: geoParts[2] }
            }
            filterGroups[type]['IN'].push(_.pickBy(geoFilters, gf => gf.name))
          } else {
            // f.split('/').forEach(part => filterGroups[type]['IN'].push(part))
            filterGroups[type]['IN'].push(f)
          }
        }
      }
    })
    return null
  })

  let filteredItems = []

  if (filterGroups.Geo.IN.length || filterGroups.Geo.NOT_IN.length) {
    if (filterGroups.Geo.IN.length) {
      filterGroups.Geo.IN.forEach(filt => {
        filteredItems = [...filteredItems, ..._.filter(items, { de: filt })]
      })
    }
    if (filterGroups.Geo.NOT_IN.length) {
      filterGroups.Geo.NOT_IN.forEach(filt => {
        filteredItems = _.reject(filteredItems, { de: filt })
      })
    }
  } else {
    filteredItems = items
  }

  const otherTypes = ['HotelType', 'Traveltypes']
  otherTypes.forEach(type => {
    const arrayIn = filterGroups[type]['IN']
    if (arrayIn.length) {
      filteredItems = filteredItems.filter(product => {
        const productCategories = product.de.categories
        let categoryNames = []
        if (Array.isArray(productCategories)) {
          categoryNames = productCategories.map(c => c.title)
        }
        return categoryNames.some(r => arrayIn.includes(r))
      })
    }
    const arrayNotIn = filterGroups[type]['NOT_IN']
    if (arrayNotIn.length) {
      filteredItems = filteredItems.filter(product => {
        const productCategories = product.de.categories
        let categoryNames = []
        if (Array.isArray(productCategories)) {
          categoryNames = productCategories.map(c => c.title)
        }
        return categoryNames.every(r => !arrayNotIn.includes(r))
      })
    }
  })

  // console.log({ filtersString, filterGroups })
  // console.log()
  // console.log(filteredItems.length)
  // console.log('\n---------------------------------------------------------\n')

  return filteredItems
}

const getExcludedSubDestinations = (
  allExcludedSubDestinations,
  destination
) => {
  if (allExcludedSubDestinations.hasOwnProperty(destination)) {
    const excludedSubDestinations = allExcludedSubDestinations[destination]
    return excludedSubDestinations || []
  }

  return []
}

const isSubDestinationExcluded = (
  allExcludedSubDestinations,
  destination,
  subDestination
) => {
  const excludedSubDestinations = getExcludedSubDestinations(
    allExcludedSubDestinations,
    destination
  )

  if (excludedSubDestinations && excludedSubDestinations.length > 0) {
    return excludedSubDestinations.includes(subDestination)
  }

  return false
}

const sanitiseSubDestination = subDestination => {
  return subDestination ? subDestination.trim() : subDestination
}

const getHubSpotFormsForLang = (allForms, lang) => {
  let localeForms = allForms.filter(item => item.lang === lang)

  if (localeForms) {
    if (Array.isArray(localeForms)) {
      localeForms = localeForms[0]
    }
    if (localeForms['forms'] && localeForms['forms'].value) {
      return localeForms['forms'].value
    }
  }

  return {}
}

const processOverrideDestinations = destinations => {
  let destinationSlugs = []
  destinations.forEach(destination => {
    if (destination.slug && destination.slug.value) {
      destinationSlugs.push(destination.slug.value)
    }
  })
  return destinationSlugs
}

const processHubSpotForms = forms => {
  let processedForms = []
  forms.forEach(form => {
    const formValue = form.value
    const identifier =
      formValue && formValue.identifier && formValue.identifier.value
        ? formValue.identifier.value
        : ''
    const name =
      formValue && formValue.name && formValue.name.value
        ? formValue.name.value
        : ''
    const snippet =
      formValue && formValue.snippet && formValue.snippet.value
        ? formValue.snippet.value
        : ''
    const submitBtnClass =
      formValue && formValue.submitBtnClass && formValue.submitBtnClass.value
        ? formValue.submitBtnClass.value
        : ''
    const formHeader =
      formValue && formValue.formHeader && formValue.formHeader.value
        ? formValue.formHeader.value
        : ''
    const formSubHeader =
      formValue && formValue.formSubHeader && formValue.formSubHeader.value
        ? formValue.formSubHeader.value
        : ''
    const formDescription =
      formValue && formValue.formDescription && formValue.formDescription.value
        ? formValue.formDescription.value
        : ''
    const overrideForm =
      formValue && formValue.overrideForm && formValue.overrideForm.value
        ? formValue.overrideForm.value
        : ''
    const overrideForDestinations =
      formValue &&
      formValue.overrideForDestinations &&
      formValue.overrideForDestinations.value
        ? processOverrideDestinations(formValue.overrideForDestinations.value)
        : {}
    processedForms[identifier] = {
      identifier: identifier,
      name: name,
      snippet: snippet,
      submitBtnClass: submitBtnClass,
      formHeader: formHeader,
      formSubHeader: formSubHeader,
      formDescription: formDescription,
      overrideForm: overrideForm,
      overrideForDestinations: overrideForDestinations
    }
  })

  return processedForms
}

module.exports = {
  getApiData,
  filterProducts,
  isSubDestinationExcluded,
  sanitiseSubDestination,
  getHubSpotFormsForLang,
  processHubSpotForms
}
