import React from 'react'
import ReactModal from 'react-modal'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 0
  },

  overlay: {
    background: 'yellow'
  }
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('#yourAppElement')

const Modal = ({ modalIsOpen, toggleModal, children }) => {
  return (
    <ReactModal
      isOpen={modalIsOpen}
      onRequestClose={toggleModal}
      style={customStyles}
      closeTimeoutMS={10}
      ariaHideApp={false}
      portalClassName='ReactModalPortal component-modal'
    >
      <button className='modal-close' onClick={toggleModal}>
        <i className='ki ki-close-a' />
      </button>
      {children}
    </ReactModal>
  )
}

export default Modal
