import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import jQuery from 'jquery'

// ENV variables
const envFile = require('../../env')
const env = envFile.env[process.env.BENV || 'prod']

const GoogleTagManager = () => {
  
  const initializeGTM = () => {
    // check if GTM already inited, if not init GTM. Note: change state of value can't be used because of React warning
    if (
      window &&
      window['google_tag_manager'] &&
      window['google_tag_manager'].dataLayer &&
      window['google_tag_manager'].dataLayer.gtmDom
    ) {
      // fire a trigger on page change
      TagManager.dataLayer({
        dataLayer: {
          event: 'gatsby-route-change'
        }
      })
    } else {
      const tagManagerArgs = {
        // testing GTM GTM-5K4V2BL
        gtmId: env.GTM || '',
        events: {
          'gatsby-rount-change': 'PageChanged'
        }
      }
      const additionalTagManagerArgs = {
        gtmId: env.additionalGTM || '',
        events: {
          'gatsby-rount-change': 'PageChanged'
        }
      }
      window.jQuery = jQuery
      // if not inited then do
      env.GTM && TagManager.initialize(tagManagerArgs)
      env.additionalGTM && TagManager.initialize(additionalTagManagerArgs)
    }
  }

  useEffect(() => {
    initializeGTM()
  }, [])

  return null
}

export default GoogleTagManager
