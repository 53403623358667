import { navigate } from 'gatsby'
import _ from 'lodash'

// Projects
const envFile = require('../../../env')
const env = envFile.env[process.env.BENV || 'prod']

const ProjectRedirect = ({ pageContext }) => {
  const { destination } = pageContext

  if (
    env.destinations &&
    !_.includes(env.destinations, destination.name.value)
  ) {
    const link = envFile.env['prod'].url + destination.slug.value
    if (typeof window !== 'undefined') {
      navigate(link)
    }
  }

  return null
}

export default ProjectRedirect
