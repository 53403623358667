const url = 'https://res.cloudinary.com/kr-reisen/image/upload'

export const getFluidImage = ({
  cloudinary,
  options,
  maxWidth = 1920,
  widths = [320, 363, 480, 768, 992, 1200, 1920],
  ar = 2.8, // aspect ratio
  transformation = '',
  // format = 'jpg'
  format = 'webp'
}) => {
  const defaultId = '' // TODO: specify default image
  const defaultOptions = `f_auto,q_auto:good,ar_${ar},c_crop`

  const calcOptions = `${defaultOptions}${options ? ',' + options : ''}`

  const src = `${url}/${
    transformation ? transformation + '/' : ''
  }${calcOptions}/w_${maxWidth}/${cloudinary || defaultId}.${format}`

  const srcSet = widths
    .map(
      width =>
        `${url}/${
          transformation ? transformation + '/' : ''
        }${calcOptions}/w_${width}/${
          cloudinary || defaultId
        }.${format} ${width}w`
    )
    .join(',')

  const sizes = `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`

  return {
    aspectRatio: ar,
    sizes,
    src,
    srcSet
  }
}

// export const getRawImage = ({ cloudinary, width = 768, format = 'jpg' }) => {
//   if (!cloudinary) {
//     return ''
//   }
//   return {
//     src: `${url}/w_${width}/${cloudinary}.${format}`
//   }
// }
