import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'

import { getFluidImage } from '../utils/images'
import { useInterval, useWindowSize } from '../hooks'

const HeaderImages = props => {
  const { images } = props
  const [imageIndex, setImageIndex] = useState(0)
  const prevIndex = (imageIndex + images.length - 1) % images.length

  const size = useWindowSize()
  const { isDesktop } = size

  useInterval(() => {
    const nextIndex = (imageIndex + 1) % images.length
    setImageIndex(nextIndex)
  }, 6000)

  return (
    <div className='header-images'>
      {images.map((image, index) => {
        let className = ''
        if (index === prevIndex) {
          className = 'prev fade-out'
        }
        if (index === imageIndex) {
          className = 'current'
        }
        return (
          <Img
            key={index}
            fluid={getFluidImage({
              cloudinary: image?.public_id
            })}
            alt={image?.meta?.description || 'hero background'}
            title={image?.meta?.description}
            objectFit='cover'
            objectPosition='50% 50%'
            style={{ height: isDesktop ? 450 : 647 }}
            imgStyle={{ height: isDesktop ? 450 : 647 }}
            className={className}
            loading={`${index === 0 ? 'eager' : 'lazy'}`}
            placeholderStyle={{ backgroundColor: 'lightgray' }}
          />
        )
      })}
    </div>
  )
}

export default HeaderImages
